import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Typo from '~/components/Typo'
import Link from '~/components/Link'
import getEntityLink from '~/utils/getEntityLink'
import clsx from 'clsx'
import { fromGlobalId } from 'graphql-relay'
import Years from '~/components/Years'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import LoadBackgroundImage from '~/components/LoadBackgroundImage'
import { fontSecondary } from '~/theme'

const styles = (theme) => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      '& $body': {
        margin: '0 -15px -10px -15px',
        padding: '0 15px 10px 15px',
        backgroundColor: theme.palette.primary.light,
      },
      '& $artwork': {
        backgroundBlendMode: 'luminosity',
        backgroundColor: theme.palette.primary.main,
      },
      '& $title': {
        color: 'white',
      },
    },
  },
  artwork: {
    flexGrow: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    height: 200,
    transition: 'all .25s',
  },
  body: {
    transition: 'all .25s',
  },
  title: {
    transition: 'all .25s',
    marginTop: 10,
  },
  authors: {
    fontFamily: fontSecondary,
  },
  dateLocationLine: {
    '& > *': {
      display: 'inline',
    },
  },
})

const EntityItem = ({
  globalId,
  thumbnail,
  name,
  editor,
  slug,
  headline,
  authors,
  startYear,
  endYear,
  location,
  classes,
  className,
}) => {
  const entityType = fromGlobalId(globalId).type
  const isStory = entityType === 'PostList'
  let displayDate = false
  if (startYear) {
    if (['Person', 'Group'].indexOf(entityType) > -1) {
      if (endYear) {
        displayDate = true
      }
    } else {
      displayDate = true
    }
  }
  return (
    <Link {...getEntityLink(globalId, slug)}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={clsx(classes.item, className)}>
        <LoadBackgroundImage src={thumbnail} className={classes.artwork} />
        <div className={classes.body}>
          <Typo
            variant={isStory ? 'itemStoryTitle' : 'itemEntityTitle'}
            className={classes.title}
          >
            {name}
          </Typo>
          <Box>
            {headline && (
              <Typography color="textPrimary">{headline}</Typography>
            )}
            {isStory && editor && (
              <Typography variant="body2" color="textPrimary">
                Par {editor}
              </Typography>
            )}
          </Box>
          {authors && authors.length > 0 && (
            <Typography color="textPrimary" className={classes.authors}>
              {authors.join(', ')}
            </Typography>
          )}
          <div className={classes.dateLocationLine}>
            {displayDate && <Years start={startYear} end={endYear} />}
            {location && (
              <Typography color="textPrimary">
                {displayDate && ', '}
                {location}
              </Typography>
            )}
          </div>
        </div>
      </a>
    </Link>
  )
}

export default compose(withStyles(styles))(EntityItem)
