import { useQuery } from '@apollo/react-hooks'
import Button from '@material-ui/core/Button'
import React from 'react'
import gql from 'graphql-tag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faSoundcloud } from '@fortawesome/free-brands-svg-icons'

interface Props {
  id: string
}

interface ICONS {
  [key: string]: IconDefinition | undefined
}
const ICONS: ICONS = {
  YouTube: faYoutube,
  SoundCloud: faSoundcloud,
}
const PostMediaProviderIcon = ({ id }: Props) => {
  const { data } = useQuery(
    gql`
      query getPostMediaProvider($id: ID!) {
        post(id: $id) {
          id
          mediaUrl
          mediaProvider
        }
      }
    `,
    { variables: { id } }
  )
  if (!data || !data.post) {
    return null
  }
  return (
    <Button href={data.post.mediaUrl} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon
        icon={
          ICONS[data.post.mediaProvider as 'YouTube' | 'SoundCloud' | string] ||
          faMusic
        }
      />
    </Button>
  )
}

export default PostMediaProviderIcon
