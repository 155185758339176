import React from 'react'
import Typography from '@material-ui/core/Typography'
import dayjs from 'dayjs'

const Years = ({ start, end, ...other }) => {
  start =
    start && (Number.isInteger(start) ? start : dayjs(start).format('YYYY'))
  end = end && (Number.isInteger(end) ? end : dayjs(end).format('YYYY'))
  return (
    <Typography {...other} color="textPrimary" component="span">
      {start && !end && start}
      {start && end && (start === end ? start : `${start}\u00a0-\u00a0${end}`)}
    </Typography>
  )
}

export default Years
