import React from 'react'
import withIntersectionObserver from '~/utils/withIntersectionObserver'
import compose from 'recompose/compose'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  root: {
    position: 'relative',
    '&:after': {
      content: '""',
      pointerEvents: 'none',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      backgroundColor: theme.palette.primary[500],
      opacity: 0,
      transition: 'all .55s',
      '$isImageLoading&': {
        opacity: 1,
      },
    },
  },
  isImageLoading: {},
})

class LoadBackgroundImage extends React.Component {
  state = {
    isImageLoading: true,
  }

  componentDidMount() {
    this.loadVisibleImage()
  }

  componentDidUpdate() {
    this.loadVisibleImage()
  }

  componentWillUnmount() {
    if (!this.img) {
      return
    }
    this.img.onload = () => {}
    delete this.img
  }

  loadVisibleImage = () => {
    const { isImageLoading } = this.state
    const { isVisible, src } = this.props
    if (isImageLoading && isVisible && src) {
      this.img = new Image()
      this.img.src = src
      this.img.onload = () => {
        this.setState({ isImageLoading: false })
      }
    }
  }

  render() {
    const { isImageLoading } = this.state
    const { classes, src, className, isVisible, ...props } = this.props
    return (
      <div
        className={clsx(
          classes.root,
          { [classes.isImageLoading]: isImageLoading },
          className
        )}
        style={{
          backgroundImage: !isImageLoading && isVisible ? `url(${src})` : null,
        }}
        {...props}
      />
    )
  }
}

export default compose(
  withStyles(styles),
  withIntersectionObserver(0)
)(LoadBackgroundImage)
