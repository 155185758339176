import React from 'react'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PlayIcon from '@material-ui/icons/PlayArrow'
import FavoriteButton from '~/components/FavoriteButton'
import Typo from '~/components/Typo'
import clsx from 'clsx'
import formatDuration from 'format-duration'
import { withDarkTheme } from '~/theme'
import LoadBackgroundImage from '~/components/LoadBackgroundImage'
import { fade } from '@material-ui/core/styles/colorManipulator'
import PostMediaProviderIconButton from '~/components/PostMediaProviderIconButton'

const HEIGHT = 200

const styles = (theme) => ({
  root: {
    padding: 10,
    borderRadius: 7,
    overflow: 'hidden',
    minHeight: HEIGHT,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-end',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundBlendMode: 'color-burn',
    transition: 'all .5s ease-in-out',
    boxSizing: 'border-box',
    backgroundColor: fade(theme.palette.primary[500], 0.25),
    '&:hover': {
      backgroundColor: theme.palette.primary[500],
      '& $info': {
        opacity: 1,
      },
    },
    '&$big': {
      minHeight: HEIGHT * 2,
    },
  },
  big: {},
  title: {
    flexGrow: 1,
    transition: 'all .31s',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '& > *': {
      display: 'inline',
      verticalAlign: 'middle',
    },
  },
  entities: {},
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 0,
    color: 'white',
    transition: 'all .3s',
  },
  actions: {
    display: 'flex',
  },
})

const PlayableItem = ({
  globalId,
  thumbnail,
  name,
  provider,
  duration,
  classes,
  className,
  onPlayClick,
  big,
}) => {
  return (
    <LoadBackgroundImage
      src={thumbnail}
      className={clsx(classes.root, { [classes.big]: big }, className)}
    >
      <div className={classes.title} onClick={onPlayClick}>
        <PlayIcon />
        <Typo variant="itemPostTitle">{name}</Typo>
      </div>
      <div className={classes.info}>
        <Box display="flex" alignItems="center">
          <Typography>{duration && formatDuration(duration * 1000)}</Typography>
          <PostMediaProviderIconButton id={globalId} />
        </Box>
        <div className={classes.actions}>
          <div>
            <FavoriteButton objectId={globalId} iconButton star />
          </div>
        </div>
      </div>
    </LoadBackgroundImage>
  )
}

export default compose(withDarkTheme, withStyles(styles))(PlayableItem)
